<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Home</h2>
                    <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                       
                       
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="new-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-img">
                    <img src="assets/img/about.jpg" alt="New">
                    <img src="assets/img/about-shape.png" alt="New">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <span class="sub-title">Explore New Career</span>
                        <h2>Who we are</h2>
                    </div>
                    <p>Fikisa Tech Recruitment is a professional niche specialist search company. We dedicate our time and resources in recruiting specialist roles in the Information Technology discipline. </p>
                    <p>Our drive is to deliver excellent talent to our partners by using disruptive technology to stay abreast, enabling us to present top talent in the IT industry to our clients. </p>
                   
                    <div class="section-title">
                        
                        <h2>Why choose us</h2>
                    </div>

                    <p>
                        We are a proudly South African organisation that understands what the market is in demand of and thrive in also understanding our clients’ needs by obtaining the correct requirements and ensuring we provide the correct skill set and culture fit candidates.
                    </p>

                    <p>
                        Our service offering is fully flexible and tailored to each individual client. With specialist recruitment consultants fulfilling Retained Search, Contingency and Contract assignments we have the capacity to deliver in any size project.
                    </p>

                   
                    <a class="cmn-btn" routerLink="/contact">Contact Us<i class='bx bx-plus'></i></a>


                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End New -->

<!-- Counter -->

<!--
<div class="counter-area two pt-100">
    <div class="counter-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape"></div>

    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-hiring"></i>
                    <p>CANDIDATES</p>
                    <h3><span class="odometer" data-count="87360">00</span> </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-candidate"></i>
                    <p>TOTAL JOBS</p>
                    <h3><span class="odometer" data-count="45257">00</span> </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-working-at-home"></i>
                    <p>EMPLOYERS</p>
                    <h3>
                        <span class="odometer" data-count="9650">00</span> 
                    </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-choice"></i>
                    <p>JOB APPLICATIONS</p>
                    <h3><span class="odometer" data-count="70299">00</span> </h3>
                </div>
            </div>
        </div>
    </div>
</div>
-->
<!-- End Counter -->

<!-- Work -->
<section class="work-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Possibilities</span>
            <h2>Our Specialisation</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-verify"></i>
                    <span>01</span>
                    <h3>Web & Application Development (JAVA, C#, PYTHON)</h3>
                  
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-file"></i>
                    <span>02</span>
                    <h3>Data Science / Analytics</h3>
                  
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>03</span>
                    <h3>Data Engineering</h3>
                     
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>04</span>
                    <h3>Architecture</h3>
                    
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>05</span>
                    <h3>Infrastructure</h3>
                    
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>06</span>
                    <h3>Systems Analysis</h3>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work -->

<!-- Explore -->
<!--<section class="explore-area ptb-100">
    <div class="container">
        <div class="explore-item">
            <div class="section-title">
                <span class="sub-title">Explore New Life</span>
                <h2>Be Found Put Your Cv In Front Of Great Employers</h2>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut tur incidunt laborequaerat voluptatem.</p>
            <ul>
                <li>
                    <a class="left-btn" routerLink="/">Upload Your Resume <i class="flaticon-upload"></i></a>
                </li>
                <li>
                    <span>CEO Message</span>
                    <a class="right-btn popup-youtube" href="https://www.youtube.com/watch?v=07d2dXHYb94&t=88s"><i class='bx bx-play'></i></a>
                </li>
            </ul>
        </div>
    </div>
</section> -->
<!-- End Explore -->

<!-- Candidate 
<section class="candidate-area two ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Working Process</span>
            <h2>Leadership Team</h2>
        </div>

        <div class="candidate-slider owl-theme owl-carousel">
            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Arielle Terry</a></h3>
                    <span>Web Developer</span>
                    <p>I’m IRanin dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>Php</li>
                        <li>jQuery</li>
                        <li>WordPress</li>
                        <li>CSS3</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate1.jpg" alt="Candidate">
            </div>

            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Alexander Max</a></h3>
                    <span>Senior UX/UI Designer</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate2.jpg" alt="Candidate">
            </div>

            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Tom Henry</a></h3>
                    <span>SEO</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate3.jpg" alt="Candidate">
            </div>
        </div>
    </div>
</section>
 End Candidate -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->